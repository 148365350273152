body {
  &.-thin {
    width: 90%;
    max-width: 380px;
    padding: $gutter;
  }
}

.main {
  position: relative;
  z-index: 0;
  background-color: $cDefault;
  > * {
    position: relative;
    z-index: 1;
  }
}