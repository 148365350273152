// FONTS
$typeSize         : 18px;

// @font-face {
//   font-family: ff-din;
//   src: url('./../type/DIN/FF-DIN-Black.otf');
// }


$type_files--path : "../font/din/";
@font-face {
  font-family: 'FFDINWebProLight';
  src: url($type_files--path + '34B10A_0_0.eot');
  src: url($type_files--path + '34B10A_0_0.eot?#iefix') format('embedded-opentype'),
       url($type_files--path + '34B10A_0_0.woff2') format('woff2'),
       url($type_files--path + '34B10A_0_0.woff') format('woff'),
       url($type_files--path + '34B10A_0_0.ttf') format('truetype');
}
 
  
@font-face {font-family: 'FFDINWebProMedium';
  src: url($type_files--path + '34B10A_1_0.eot');
  src: url($type_files--path + '34B10A_1_0.eot?#iefix') format('embedded-opentype'),
       url($type_files--path + '34B10A_1_0.woff2') format('woff2'),
       url($type_files--path + '34B10A_1_0.woff') format('woff'),
       url($type_files--path + '34B10A_1_0.ttf') format('truetype');
}
 
  
@font-face {font-family: 'FFDINWebProBold';
  src: url($type_files--path + '34B10A_2_0.eot');
  src: url($type_files--path + '34B10A_2_0.eot?#iefix') format('embedded-opentype'),
       url($type_files--path + '34B10A_2_0.woff2') format('woff2'),
       url($type_files--path + '34B10A_2_0.woff') format('woff'),
       url($type_files--path + '34B10A_2_0.ttf') format('truetype');
}

$type_style--light  : "FFDINWebProLight", sans-serif;
$type_style--medium : "FFDINWebProMedium", sans-serif;
$type_style--bold   : "FFDINWebProBold", sans-serif;