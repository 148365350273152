.footer {
  position: relative;
  z-index: 1;
  max-width: 100%;
  background-color: $cPrimary;
  color: $cSecondary;
}


// TOP
.footer--top {
  @include clearFix;
  padding: 0 calc($gutter / 2);
}

.footer--top_left,
.footer--top_right {
  float: left;
  width: 100%;
  @include breakpoint($tp) {
    @include span(1 of 2 nest);
  }
}

.footer--top_left {
  // background-color: c("grey");
  @include breakpoint($tp) {
    text-align: left;
  }
}

.footer--top_right {
  // background-color: c("grey light");
    text-align: center;
  @include breakpoint($tp) {
    text-align: left;
  }
}

.footer--top_container {
  float: left;
  width: 100%;
  @include breakpoint($tp) {
    float: none;
    max-width: calc($containerMax / 2);
    .footer--top_left & {
      margin-left: auto;
    }
    .footer--top_right & {
      margin-right: auto;
    }
  }
}

.footer--top_inner {
  @include span(1 of 1 nest);
  @include breakpoint($tp) {
    .footer--top_left & {
      padding: 0 calc($gutter / 2) 0 $gutter;
    }
    .footer--top_right & {
      padding: 0 $gutter 0 calc($gutter / 2);
    }
  }
}

.footer--top_content {
  @include span($columns nest);
  padding: $gutter;
  @include breakpoint($ml) {
  }
  @include breakpoint($tp) {
    height: 250px;
    padding: $gutter calc($gutter / 2);
    .footer--top_right & {
      @include vAlign(".footer--top_form");
    }
  }
  .h3{
    margin-top: 0;
  }
}

.footer--top_left--images {
  float: left;
  width: 100%;
  text-align: left;
  padding: $gutter 0;
  @include breakpoint($ml) {
    text-align: center;
  }
  img {
    width: 100%;
    max-width: 200px;
    margin: 0 auto $gutter auto;
    @include breakpoint($ml) {
      float: left;
      width: 40%;
      &:nth-child(even) {
        margin-left: 10%;
      }
    }
    @include breakpoint($ds) {
      width: 30%;
      &:nth-child(even) {
        margin-left: 20%;
      }
    }
  }
}

.footer--top_form {
  margin: auto;
  text-align: left;
  color: $cPrimary;
  .h3 {
    color: $cSecondary;
  }
  @include breakpoint($tp) {
    width: 380px;
  }
}


// MIDDLE
.footer--nav {
  padding: 0 calc($gutter / 2);
  background-color: c("black");
  color: c("white");
  .-axModeOn & {
    background-color: c("jet black");
    a {
      color: c("grey");
    }
  }
}

.footer--nav_wrapper {
  @include span($columns nest);
  // padding: 0 calc($gutter / 2);
}

.footer--nav_inner {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  padding: 0 0 0 calc($gutter / 2);
  border: $borderWidth solid mix($cSecondary, $cPrimary, 40%);
  border-radius: 1px;
  position: relative; // so that any absolute positioning within can be relative to this container
  a {
      @extend .text--body--very_small;
      color: $cSecondary;
      text-decoration: none;
      &:hover {
        color: $cHilight;
        text-decoration: underline;
    }
  }
}

.footer--nav_col {
  // Default: full width on small screens
  flex: 0 0 100%;
  padding: calc($gutter / 2);

  @include breakpoint($ml) {
    // Two columns per row on medium screens
    flex: 0 0 50%;
  }

  @include breakpoint($tl) {
    // For example, six columns across (adjust the percentage as needed)
    flex: 0 0 calc(100% / 5);
  }
}

.footer--nav_list {
  @include clearList;
  padding: 0;
  @include breakpoint($ml) {
    // padding: $gutter $textIndent;
  }
}

.footer--nav_links {
  // Make this a flex container for the links
  display: flex;
  flex-direction: row;
  align-items: stretch;
  // By default, push social links to the end of the row
  margin-left: auto;
  order: 1; // Ensure it appears after the nav columns when wrapping
  width: 100%;
  margin: 2rem 0;
  justify-content: flex-start;

  @include breakpoint($ml) {
    // Optionally, change order if needed at medium breakpoints
    order: 2;
  }

  @include breakpoint($tl) {
    // At large screens, you might want to set a fixed width
    order: 0;
    justify-content: flex-end;
    margin-left: auto; // remains flush right
    margin-top: 0;
    margin-bottom: 0;
    width: calc(100% / 5); // matching your original span(calc($columns/3))
  }
}

$footerNavLinksWidthTl: 80px;
$footerNavLinksSizeTl : 30px;
.footer--nav_links>a {
  @include font-size(2.6);
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  position: relative;
  padding: 0 calc($gutter / 4);
  text-align: center;

  @include breakpoint($tl) {
    // Instead of using absolute positioning, we now rely on flex alignment.
    // Add a left border to each link (except perhaps the first, adjust as desired)
    border-left: $borderWidth solid mix($cSecondary, $cPrimary, 40%);
    flex: 1;

    svg {
      // Adjust the svg positioning if necessary – here we centre it
      display: block;
      margin: 0 auto;
      width: $footerNavLinksSizeTl;
      height: $footerNavLinksSizeTl * 2;
    }
  }
}


// BOTTOM
$footerBottomHeight: $gutter*2;
$footerBottomBorder: $borderWidth solid c("grey medium");

.footer--bottom {
  padding: 0 calc($gutter / 2);
  // border-top: $footerBottomBorder;
  // border-bottom: $footerBottomBorder;
}

.footer--bottom_info {
  padding: $gutter;
  p {
    @extend .text--body--very_small;
  }
}



.footer--nav_info {
  float: left;
  p {
    @include font-size(1.2);
    line-height: $footerBottomHeight;
  }
}








