$bannerBorder     : $borderWidth solid mix($cPrimary, $cSecondary, 30%);
$bannerBarHeight  : 50px;
$bannerHeightWide : $bannerBarHeight*3;
$bannerHeight     : 50px;
$bannerBreak      : $tl;


//DELETE ME
#mfPreviewBarShow,
#mfPreviewBar {
  display: none;
}



.banner {
  position: relative;
  z-index: 100;
  @include breakpoint($bannerBreak) {
    background: linear-gradient(90deg, #1e1834 0%, #1e1834 50%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%)
  }
}

.banner--container {
  position: relative;
}

.banner--inner {
  @include breakpoint($tl) {
    @include span(24);
  }
}

.banner--burger {
  float: left;
  @include breakpoint($bannerBreak) {
    display: none;
  }
}

.banner--logo {
  position: relative;
  float: left;
  height: $bannerHeight;
  text-align: left;
  color: white;
  img {
    display: none;
  }
  .banner--logo--mobile {
    line-height: $bannerBarHeight;
    padding: 0 10px;
    color: $cPrimary;
    display: inline;
  }
  @include breakpoint($bannerBreak) {
    @include span(5);
    height: $bannerHeightWide;
    padding: 0 $textIndent;
    border-right: $bannerBorder;
    img {
      display: block;
      position: absolute;
      bottom: 10px;
      left: 0px;
      height: 85px;
    }
    .banner--logo--mobile {
      display: none;
    }
  }
}

.banner--mobile--icon_right {
  float: right;
  width: $bannerHeight;
  height: $bannerHeight;
  line-height: $bannerHeight;
  text-align: center;
  border-left: $bannerBorder;
  a {
    color: $cPrimary;
    &:hover {
      color: $cHilight;
    }
  }
  @include breakpoint($bannerBreak) {
    display: none;
  }
}

.banner--menu {
  @include span(19 nest);
  background: c("white");
}

.banner--nav {
  display: none;
  position: absolute;
  top: $bannerHeight; right: 0; bottom: auto; left: 0; z-index: -1;
  padding: calc($gutter / 2) 0;
  background: c("white");
  border: $bannerBorder;
  box-shadow: $boxShadow;
  @include breakpoint($tp) {
    right: calc($gutter / 2); left: calc($gutter / 2);
  }
  @include breakpoint($bannerBreak) {
    @include span(19 of 19 nest);
    display: block;
    position: relative;
    top: auto; right: auto; bottom: auto; left: auto; z-index: 1;
    padding-top: 0;
    padding-bottom: 0;
    background: c("white");
    border: 0;
    box-shadow: none;
  }
}

.banner--nav_top,
.banner--nav_bottom {
  @include clearList;
  a {
    @include font-size(1.4);
    display: inline-block;
    text-decoration: none;
    color: $cPrimary;
    font-family: $type_style--medium;
    &:hover {
      color: $cHilight;
    }
  }
  > li > a,
  > li > div > a {
    padding: 0 calc($gutter / 2);
    @include breakpoint($ds) {
      padding: 0 $gutter;
    }
  }
  @include breakpoint($bannerBreak) {
    height: $bannerBarHeight;
    line-height: $bannerBarHeight - 2px;
  }
}

.banner--nav_top {
  display: none;
  @include breakpoint($bannerBreak) {
    display: block;
    float: right;
    width: auto;
    margin-bottom: $bannerBarHeight;
    border-right: $bannerBorder;
    > li {
      float: right;
      background-color: $cSecondary;
      &:nth-child(1),
      &:nth-child(2) {
        position: relative;
        z-index: 2;
        border-left: $bannerBorder;
        border-bottom: $bannerBorder;
      }
    }
  }
}


li.banner--nav_top--li_small > a,
li.banner--nav_top--li_small > p {
  @extend .text--body--very_small;
  padding: 0 10px;
  margin: 0;
}
li.banner--nav_top--li_small > p {
  display: inline-block;
  cursor: pointer;
  color: $cPrimary;
  font-family: $type_style--medium;
  &:hover {
    color: $cHilight;
  }
}

.banner--nav--search {
  position: relative;
  label,
  input {
    @extend .text--body--small;
  }
  label {
    position: absolute;
    top: 0; right: auto; bottom: 0; left: 0; z-index: 1;
    padding: 0px calc( $gutter / 1.5) 0 calc( $gutter / 1.5);
    color: $cDull;
    line-height: 50px;
  }
  input {
    position: relative;
    z-index: 2;
    width: 250px;
    margin-top: 0px;
    border: 0;
    margin-right: $bannerBarHeight;
    background-color: transparent;
    line-height: 38px;
    &:focus,
    &.-contentEntered {
      background-color: $cSecondary;
    }
  }
  button {
    @extend %clearButtonStyle;
    position: absolute;
    top: 0; right: 0; bottom: 0; left: auto; z-index: 3;
    width: $bannerBarHeight;
    padding: 6px 0 0 0;
  }
}

.banner--nav--search_li {
  position: relative;
}

.banner--nav--search_wrapper {
  position: absolute;
  right: 0; top: -$bannerBarHeight; z-index: 10;
  height: $bannerBarHeight;
  border-left: $bannerBorder;
  border-bottom: $bannerBorder;
  background-color: $cSecondary;
  transition: 0.25s top ease;
  .-searchShow & {
    top: 0;
  }
}

.banner--nav_bottom {
  @include breakpoint($bannerBreak) {
    float: left;
    width: 100%;
    border-top: $bannerBorder;
    border-right: $bannerBorder;
    > li {
      position: relative;
      float: left;
      &.banner--nav_bottom--more {
        float: right;
      }
    }
  }
}

#moreSubMenuParent {
  display: none;
  @include breakpoint($bannerBreak) {
    display: block;
  }
}

.banner--nav_sublist li{
  @include breakpoint($bannerBreak) {
    padding: 0 10px;
  }
}

.banner--nav_sublist {
  @include clearList;
  // position: absolute;
  // top: 100%;
  // right: 0;
  // z-index: 10;
  // background-color: $cSecondary;
  // line-height: 1;
  // text-align: right;
  li {
    padding: 0px;
    line-height: 1.2;
  }
  a {
    padding: calc($gutter / 3) calc($gutter / 2);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
  }
  ul {
    margin-bottom: calc($gutter / 2);
    li:first-child {
      a {
        padding-top: 0;
      }
    }
  }
  @include breakpoint($bannerBreak) {
    display: none;
    float: left;
    position: absolute;
    top: 100%; right: auto; bottom: auto; left: -$borderWidth; z-index: 10;
    padding: 10px;
    background-color: $cSecondary;
    border: $bannerBorder;
    line-height: 1.4;
    li {
      line-height: 2;
    }
    [aria-expanded='true'] & {
      display: block;
    }
    .banner--nav_account & {
      top: 100%; right: -$borderWidth; bottom: auto; left: auto;
      text-align: right;
    }
    .banner--nav_bottom--more & {
      top: 100%; right: -$borderWidth; bottom: auto; left: auto;
      text-align: right;
    }
  }
}

.banner--nav_account {
  position: relative;
}

.banner--nav_subsublist {
  list-style-type: none;
  padding-left: calc($gutter / 2);
  > li > a {
    font-family: $type_style--light;
    line-height: 1em;
  }
}

.banner--buttons {
  float: right;
  @include breakpoint($bannerBreak) {
    @include span(6 of 19 nest);
  }
}

.banner--burger_button,
.banner--button {
  @include font-size(1.5);
  float: left;
  position: relative;
  width: $bannerHeight;
  line-height: $bannerHeight;
  text-align: center;
  border-right: $bannerBorder;
  &:first-child {
    border-left: $bannerBorder;
  }
  @include breakpoint($bannerBreak) {
    @include font-size(1.8);
    @include span(1 of 3 nest);
    line-height: $bannerHeightWide;
  }
}

.banner--burger_button {
  @extend %clearButtonStyle;
  margin: 0;
  padding: 0;
  color: inherit;
}


.banner--mobile--icon_right.-account {
  .banner--nav_sublist {
    visibility: hidden;
    opacity: 0;
    height: 0px;

    position: absolute;
    right: -1px;
    top: 100%;
    text-align: right;
    background: white;
    border: $bannerBorder;
    li {
      a {
        display: block;
      }
    }
  }

  &.-showList {
    .banner--nav_sublist {
      visibility: visible;
      opacity: 1;
      height: auto;
    }
  }
}


// GOOGLE TRANSLATE BAR
#google_translate_element {
  display: none;
  padding: calc($gutter / 2);
  border-bottom: $bannerBorder;
  .goog-te-gadget select.goog-te-combo {
    width: auto;
    background-color: #fff;
    line-height: 1;
    color: black;
    -webkit-appearance: menulist;
    margin: 0;
  }
}


// body {
//   background-color: #333;
//   color: #fff;
// }
// .nav {
//   padding: 0;
//   list-style: none;
//   a {
//     padding: .5em 1em;
//     background-color: #555;
//     color: #fff;
//     text-decoration: none;
//     &:hover, &:focus {
//       text-decoration: underline;
//     }
//   }
//   > .menu-item {
//     position: relative;
//     display: inline-block;
//     margin-right: .1em;
//   }
//   .sub-menu-wrapper {
//             position: absolute;
//             z-index: 999;
//             top: 1.1em;
//             left: 0;
//             width: 13em;
//       padding-top: .4em;
//   }
//   .sub-menu {
//     padding: 0;
//     list-style: none;
//     a {
//       display: block;
//       background-color: #666;
//       text-align: left;
//     }
//   }
//     .menu-level-0 {
//         &[aria-expanded='false'] {
//             .sub-menu-wrapper {
//                 display: none;
//             }
//         }
//     }
// }