.listing_search {
  @include span($columns nest);
  padding: $gutter;
  background-color: $cGhost;
  // border: $borderWidth solid $cGhost;
  border-radius: $borderRadius;
}

.-searchBlock {
  padding-bottom: $gutter;
  background-color: lighten(c("grey"), 3%);
}
#results div[data-enabled="false"]{
    display: none;
}
#resetSearchBtn{
	cursor: pointer;
	margin-right: 10px;
}