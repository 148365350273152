// PAGE BODY MODULE
// ================

// SIDEBAR (RIGHT SIDE)
.sidebar {
  @include span($columns);
  color: $cSecondary;
  @include breakpoint($sidebarBreak) {
    padding-left: 0;
    padding-right: 0;
    width: $sidebarWidth;
  }
}

.sidebar--section {
  @include span($columns nest);
  padding-top: $gutter;
  padding-bottom: $gutter;
  color: $cLowlight;
  @include breakpoint($sidebarBreak) {
    // padding-top: $gutter*2;
    padding-left: $gutter;
    padding-right: $gutter;
    &.-top {
      color: $cSecondary;
      &.-backgroundImage {
        height: auto;
        color: $cLowlight;
      }
    }
  }
  &.-ad {
    position: relative;
    color: $cDull;
    display: none;
    img {
      width: 100%;
      height: auto;
    }
    @include breakpoint($sidebarBreak) {
      padding: calc(calc($sidebarWidth - $advertWidth) / 2);
      padding-bottom: 0;
      display: block;
    }
    .tmn-widget{
      margin: 0;
      .fluid-v1{
        border: none;
        border-radius: 0;
      }
    }
  }
  &.-moProfile {
    display: none;
    @include breakpoint($sidebarBreak) {
      display: block;
    }
    .h4{
      color: $cPrimary;
    }
    &.fixed{
      position: fixed;
      top: 0;
      width: $sidebarWidth;
      z-index: 10;
    }
    .section-links__sub{
      padding-left: 16px;
    }
    ul{
      list-style: none;
      display: flex;
      flex-direction: column;
      align-content: flex-start;
      align-items: flex-start;
      padding: 0;
      .section-links__link, .section-links__sub-link{
        display: inline-block;
        font-family: $type_style--medium;
        @include font-size(1.4);
        padding: 4px 8px;
        margin-left: -8px;
        border-radius: 2px;
        color: $cLinks;
        cursor: pointer;
        &.visible{
          background-color: $cBlue;
          color: $cSecondary;
        }
      }
    }
  }
}


.sidebar--ad {
  @include heightRatio(1, 1);
  background-color: $cDull;
  line-height: 3em;
  text-align: center;
  margin: 0;
  color: lighten($cDull, 5%);
}


.sidebar--related {
  position: relative;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  a {
    color: $cSecondary;
    max-width: 100%;
    &:hover {
      color: $cHilight;
    }
    .-backgroundImage & {
      color: $cPrimary;
      &:hover {
        color: $cHilight;
      }
    }
  }
  h3{
    margin-top: 0;
  }
}