$popularModuleImageHeight: 300px;
$popularModuleSpace: $gutter;
$popularModuleHeight: $popularModuleImageHeight + ($popularModuleSpace*4);


.popular_module {
  padding: $gutter*2 0;
  background-color: $cPrimary;
  color: $cSecondary;
}

.popular_module--feature{
  margin-bottom: 10px;
  @include breakpoint($tp) {
    margin-top: 10px;
  }
}

.popular_module--list,
.popular_module--feature,
.popular_module--feature_image,
.popular_module--feature_info {
  @include span($columns);
  @include breakpoint($tp) {
    @include span($columns*0.5);
  }
}
.popular_module--feature_inner{
  &.media{
    background-size: cover;
    background-position: center;
    cursor: pointer;
  }
}
.popular_module--feature_media{
  position: relative;
  padding: 0;
  padding-left: 0;
  padding-right: 0;
  text-align: center;
  height: $popularModuleHeight;
  background-repeat: no-repeat;
  background-position: 50% 40%;
  background-size: 80px;
  transition: background-size 500ms ease-in-out;
  &.video{
    background-image: url(../img/play_btn.svg);
  }
  &.image{
    background-image: url(../img/view_btn.svg);
  }
  &:hover{
      background-size: 96px;
  }
}
.popular_module--feature_overlay{
  position: absolute;
  bottom: 0;
  text-align: left;
  width: 100%;
  padding: $popularModuleSpace;
  background-color: rgba($cLowlight, 0.75);
  h3{
    margin-top: 0;
    margin-bottom: 0;
  }
}

.popular_module--list_inner,
.popular_module--feature_image,
.popular_module--feature_info {
  padding: $popularModuleSpace;
  @include breakpoint($tp) {
    height: $popularModuleHeight;
  }
  h3{
    margin-top: 0;
  }
}

.popular_module--list_inner {
  background-color: $cBlue;
  h2 {
    margin-top: 0;
  }
}

.popular_module--feature_image{
  padding-top: 40%;
  background-color: darken($cLowlight, 3%);
  text-align: center;
  background-size: cover;
  background-position: center;
  @include breakpoint($tp) {
    padding-top: $popularModuleSpace;
  }
  // height: $popularModuleHeight;
  img {
    height: $popularModuleImageHeight;
    box-shadow: 2px 2px 10px 0px rgba(black, 0.2);
  }
}

.popular_module--feature_info {
  background-color: $cLowlight;
}
.popular_module--feature_info {
  a {
    color: #FFFFFF;
    font-family: "FFDINWebProLight",sans-serif;
    font-size: 1.98rem;
    margin-top: 1em;
    display: inline-block;
  }
  a:hover{
    text-decoration: underline;
  }
}

.popular_module--ul {
  padding-left: $gutter;
  li {
    position: relative;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    a {
      @extend %aLink--focus;
      color: $cSecondary;
    }
  }
}