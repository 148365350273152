@include keyframes(popUp) {
  0% {
    transform:  translateY(100%);
  }
  100% {
    transform:  translateY(0%);
  }
}

// LOADING BUTTON ANIMATION
@include keyframes(loadBounce) {
  0% {
    transform:  translateY(0%);
  }
  50% {
    transform:  translateY(-100%);
  }
  0% {
    transform:  translateY(0%);
  }
}