.people {
  @include span($columns nest);
}

.people--person {
  @include span($columns nest);
  padding-top: $gutter;
  padding-bottom: $gutter;
  &:nth-child(odd) {
    clear: both;
  }
  @include breakpoint($mw) {
    @include span(calc($columns / 2) nest);;
  }
  @include breakpoint($tl) {
    @include span($columns nest);
  }
  @include breakpoint($ds) {
    @include span(calc($columns / 2) nest);;
  }
}

.people--person_image_profile {
  width: 144px;
  border-radius: 2px;
  display: block;
}

.people--person_image {
  @include span($columns nest);
  position: relative;
  img {
    width: 100%;
    max-width: 150px;
    border-radius: 150px;
    aspect-ratio: 1;
    object-fit: cover;
  }
  @include breakpoint($tp) {
    @include span(calc($columns / 4));;
  }
}

.people--person_wrap {
  max-width: 150px;
  margin: auto;
  p {
    margin-bottom: 0;
  }
  @include breakpoint($mw) {
    max-width: none;
    margin: auto;
  }
}

.people--person_info {
  @include span($columns nest);
  @include breakpoint($tp) {
    @include span(calc($columns / 1.5));;
  }
}
