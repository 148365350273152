.classifieds_module {
  padding: $gutter*2 0 $gutter 0;
  background-color: $cSecondary;
}

.classifieds_module--header {
  @include span($columns);
  color: $cPrimary;
  > * {
    margin-top: 0;
  }
  h2{
    float: left;
    width: calc(100% - 150px);
  }
  p{
    float: right;
    width: 150px;
    margin-top: 2em;
    text-align: right;
  }
}