fieldset {
  display: block;
  // Not sure why this was set this way...
  // float: left;
  // position: relative;
  width: 100%;
  padding: 0;
  border: 0;
  margin: 0 0 calc($gutter / 4) 0;
  legend {
    @extend .h2;
    width: 100%;
    padding: calc($gutter / 2) 0 0 0; 
    margin: 0;
    // border-bottom: $borderWidth solid c("grey medium");
  }
  &.-last {
    margin-bottom: 0;
  }
}

.input--year{
  width: 120px !important;
  padding: 9px 13.33333px !important;
  margin-left: 6.66667px;
  position: relative;
  top: 3px;
}

.input--text,
.input--password,
.input--email,
.input--tel,
.input--textarea,
.input--radio,
.input--checkbox,
.input--select,
.input--datepicker,
.input--time,
.input--button {
  @extend .text--body--small;
  font-family: $type_style--medium;
  // text-transform: uppercase;
  padding-top:10px;
  display: block;
  float: left;
  position: relative;
  width: 100%;
  margin-bottom: $inputMarginBottom;
  &.-inline {
    display: inline-block;
    width: auto;
  }
  &.-half {
    & + .-half {
      margin-top: calc($gutter / 4);
    }
    @include breakpoint($tp) {
      width: calc(50% - #{calc($gutter / 4)});
      & + .-half {
        margin-left: calc($gutter / 2);
        margin-top: 0;
      }
    }
  }
  &.-right {
    float: right;
  }
  &.-disabled {
    color: $cInputLabelD;
  }
  &.-error {
    color: $cInputLabelE;
  }
  &.-float {
    width: auto;
    display: inline;
    padding: 0;
  }
  &.-button {
    > .input--side_input {
      padding-bottom: 1px;
      > input {
        border-right: 0px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
      }
    }
    > .input--side_input,
    > .input--side_button {
      float: left;
    }
  }
  > label {
    position: relative;
    display: block;
  }
  .input--icon {
    position: absolute;
    padding: $inputPadding;
    bottom: 0;
    right: 0;
  }
}

.input--button {
  &.-left {
    text-align: left;
  }
  &.-right {
    text-align: right;
  }
  &.-center {
    text-align: center;
  }
}



