$colr-palettes:(
  "primary":(
    "white"           : #ffffff,
    "jet black"       : #000000,
    "green"           : #7C972F,
    "green dark"      : #005B64,
    "green light"     : #1acc9d,
    "blue"            : #1650B4,
    "blue dark"       : #1E1834,
    "blue light"      : #00A2AF,
    "blue very light" : #F3F6FB,
    "red"             : #EB1E00,
    "red dark"        : #470837,
    "yellow"          : #F59B0F,
    "grey"            : #E8E8EB,
    "grey light"      : #9e9a94
  )
);

// theme
$cDefault         : c("grey");
$cPrimary         : c("blue dark");
$cSecondary       : c("white");
$cLowlight        : c("blue light");
$cSubtle          : c("green");
$cHilight         : c("green light");
$cGhost           : c("blue very light");
$cInfo            : c("red dark");
$cDull            : darken(c("grey"), 20%);

// colour
$cGreen           : c("green");
$cBlue            : c("blue");
$cYellow          : c("yellow");
$cRed             : c("red");



// FONTS
// =====

// default
$cFont            : $cPrimary;
$cLinks           : c("blue");;

// accessible
$cFontAx          : $cPrimary;
$cLinksAx         : c("green light");;




// BUTTONS
// =======

// default
$cButtonBg        : $cHilight;
$cButtonBgHov     : darken($cHilight, 2%);
$cButtonTxt       : $cSecondary;
$cButtonTxtHov    : $cSecondary;

// secondary
$cButtonBgS       : $cSecondary;
$cButtonBgHovS    : darken($cDefault, 5%);
$cButtonTxtS      : $cPrimary;
$cButtonTxtHovS   : $cLowlight;

// accessible
$cButtonBgAx      : c("blue dark");
$cButtonTxtAx     : $cSecondary;
$cButtonBgHovAx   : c("black");
$cButtonTxtAx     : $cSecondary;
$cButtonTxtAxHov  : $cSecondary;




// INPUTS
// ======

// default
$cInputBorder     : mix($cPrimary, white, 25%);
$cInputBg         : c("white");

$cInputRadioBg    : mix($cPrimary, white, 1%);

// disabled
$cInputBorderD    : c("grey light");
$cInputBgD        : lighten(c("grey light"), 37%);
$cInputLabelD     : c("grey light");

// error
$cInputBorderE    : c("red");
$cInputLabelE     : c("red");

// hover
$cInputRadioBgH   : c("blue very light");

// accessible
$cInputBorderAx   : c("black");


// SELECT
// ======

$cSelectTxt            : inherit;
$cSelectTxtAx          : inherit;
$cSelectTitleTxt       : c("grey medium");
$cSelectTitleTxtAx     : c("black");

// hover
$cSelectItemHover      : c("grey");
$cSelectItemHoverAx    : c("black");
$cSelectItemHoverTxt   : inherit;
$cSelectItemHoverTxtAx : c("white");

// focus
$cSelectItemFocus      : $cSelectItemHover;
$cSelectItemFocusTxt   : inherit;





