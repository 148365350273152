// CACULATE ASPECT RATIO OF 100% WIDE ELEMENT
@mixin heightRatio($ratio1: 1, $ratio2: 1, $width: true) {

  $height: percentage(calc($ratio2 / $ratio1));

  @if $width == true {
    width: 100%;
  }
  height: 0;
  padding-bottom: $height;
}