// ARITCLE MODULE
// ==============


// ARTICLE (LEFT SIDE)
.article {
  @include span($columns nest);
  section:first-child {
    padding-top: $gutter;
    &.article--actions {
      padding-top: calc($gutter / 2);
    }
    &.article--meta_banner {
      padding-top: calc($gutter / 2);
      padding-bottom: calc($gutter / 2);
    }
  }
  section:last-child {
    padding-bottom: $gutter;
    min-height: 20vh;
  }
  @include breakpoint($mw) {
    padding-left: calc($gutter / 2);
    padding-right: calc($gutter / 2);
  }
  @include breakpoint($sidebarBreak) {
    width: calc(100% - #{$sidebarWidth});
  }
}

.article--section,
.article--section_inner {
  @include span($columns nest);
}

// Padding space
.article--section {
  @extend %articleTextSpaceLeft;
  @extend %articleTextSpaceRight;
  background-color: $cSecondary;
  h2 {
    // border-top: 2px solid #00A2AF;
    padding-top: calc($gutter * 2);
    margin-bottom: calc($gutter * 2);
    margin-top: 0
  }

}

body.memberOrganisations .article--section:not(.-photo)+.article--section{
  margin-top: $gutter
}

.article--section.-searchBlock {
  padding-top: 20px;
}

.article--section.-people h3 {
  margin-top:0;
}

.article--topImage {
  height: $pageBodyBackgroundImageHeight;
  @include breakpoint($mw) {
    height: $pageBodyBackgroundImageHeightMw;
  }
  @include breakpoint($tp) {
    height: $pageBodyBackgroundImageHeightTp;
  }
}

.article--topImage,
.sidebar--section.-top {
  @include breakpoint($tl) {
    height: $pageBodyBackgroundImageHeightTl;
  }
  @include breakpoint($ds) {
    height: $pageBodyBackgroundImageHeightDs;
  }
}

.article--topImage {
  @include span($columns nest);
  position: relative;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  + section {
    padding-top: $gutter;
  }
}

// MEDIA (IMAGES VIDEOS)
.article--media {
  @include span($columns nest);
  background-color: $cSecondary;
  iframe{
    width: 100%;
    aspect-ratio: 16/9;
    height: auto;
    object-fit: contain;
  }
}

.article--section .inRemembrance--photo{
  height: 360px;
  width: 360px;
  aspect-ratio: 1;
  object-fit: cover;
  padding-top: calc($gutter / 2);
}

.article--video {
  max-width: 100%;
  aspect-ratio: 16/9;
  object-fit: contain;
}

.article--pseudoVideo {
  max-width: 100%;
  aspect-ratio: 16/9;
  object-fit: contain;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: darken(c("blue dark"), 7%);
  padding: 2em;
  color: #FFFFFF;
  a{
    color: #FFFFFF;
    text-decoration: underline;
    &:hover{
      color: $cLinksAx;
    }
  }
}

.article--inline_faq {
  padding-bottom: $gutter;
  width: 100%;
}

.article--media_figure {
  padding-top: $gutter;
  font-size: 0;
  width: 100%;
  img {
    width: 100%;
  }
  &.--video {
    @include heightRatio(16, 9);
    position: relative;
    height: 0;
    padding-top: 0;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.article--media_figcaption {
  @extend %articleTextSpaceLeft;
  @extend %articleTextSpaceRight;
  margin: 0;
  padding-top: calc($gutter / 2);
  padding-bottom: $gutter;
}


// META (PAGE TOP INFO)
.article--meta,
.article--alert,
.article--membership {
  @include span($columns nest);
  padding-top: $gutter;
  padding-bottom: $gutter;
  + .article--section {
    padding-top: $gutter;
  }
  + .article--section h2 {
    border-top: none;
    padding-top: 0;
  }
}

.article--meta {
  border-bottom: $borderWidth solid mix($cPrimary, $cSecondary, 30%);
  background-color: $cGhost;
  display: flex;
  flex-direction: column;
}

$cAlertErrorTxt : c("red");
$cAlertErrorBg  : mix($cAlertErrorTxt, c("white"), 20%);
$cAlertErrorBdr : mix($cAlertErrorTxt, c("white"), 50%);
.article--alert {
  &.-error {
    border-bottom: $borderWidth solid $cAlertErrorBdr;
    background-color: $cAlertErrorBg;
    color: $cAlertErrorTxt;
  }
}

$cAlertExpiredTxt : #e82625;
$cAlertExpiredBg  : mix($cAlertExpiredTxt, c("white"), 10%);
$cAlertExpiredBdr : mix($cAlertExpiredTxt, c("white"), 50%);

$cAlertGraceTxt : #db840a;
$cAlertGraceBg  : mix($cAlertGraceTxt, c("white"), 10%);
$cAlertGraceBdr : mix($cAlertGraceTxt, c("white"), 50%);

$cAlertActiveTxt : #829929;
$cAlertActiveBg  : mix($cAlertActiveTxt, c("white"), 10%);
$cAlertActiveBdr : mix($cAlertActiveTxt, c("white"), 50%);

.article--membership {
  &.-expired {
    border-bottom: $borderWidth solid $cAlertExpiredBdr;
    background-color: $cAlertExpiredBg;
    color: $cAlertExpiredTxt;
  }
  &.-active {
    border-bottom: $borderWidth solid $cAlertActiveBdr;
    background-color: $cAlertActiveBg;
    color: $cAlertActiveTxt;
  }
  &.-grace {
    border-bottom: $borderWidth solid $cAlertGraceBdr;
    background-color: $cAlertGraceBg;
    color: $cAlertGraceTxt;
  }
  &.-default {
    border-bottom: $borderWidth solid $cDull;
    background-color: c("white");
    color: $cPrimary;
  }
  span{
    font-weight: 500;
  }
}
#download_certificate{
  display: inline-block;
  margin-top: 1em;
  cursor: pointer;
  &.error{
    background-color: $cDull;
    border-color: $cDull;
    pointer-events: none;
  }
}
.download-error{
    display: block;
    margin-top: 0.5em;
    color: $cInputLabelE;
}

.article--meta_inner,
.article--meta_banner_inner,
.article--alert_inner,
.article--membership_inner {
  @include span($columns nest);
  @extend %articleTextSpaceLeft;
  @extend %articleTextSpaceRight;
  position: relative;
}

.article--meta_left,
.article--meta_right,
.article--meta_right--info,
.article--meta_right--action {
  @include span($columns nest);
}

.article--meta_left {
  padding-right: $gutter;
  @include breakpoint($tp) {
    @include span(calc($columns / 2) nest);
    + .article--meta_right {
      @include span(calc($columns / 2) nest);
    }
  }
}

.article--meta_right {}

// .article--meta_right--info {
//   width: calc(100% - #{$articleSpaceRight});
//   .-backgroundImage & {
//     width: calc(100% - #{$articleSpaceRight + $gutter});
//   }
// }

.article--meta_right--action {
  padding-top: calc($gutter / 2);
  @include breakpoint($tp) {
    position: absolute;
    top: 0; right: 0; bottom: auto; left: auto;
    width: $articleSpaceRight;
    padding-top: 0;
    .-backgroundImage & {
      width: $articleSpaceRight + $gutter;
    }
  }
  @include breakpoint($tl) {
    position: relative;
    top: auto; right: auto; bottom: auto; left: auto;
    width: auto;
    padding-top: calc($gutter / 2);
    .-backgroundImage & {
      width: auto;
    }
  }
  @include breakpoint($ds) {
    position: absolute;
    top: 0; right: 0; bottom: auto; left: auto;
    width: $articleSpaceRight;
    padding-top: 0;
    .-backgroundImage & {
      width: $articleSpaceRight + $gutter;
    }
  }
}

.article--meta--label,
.article--meta--text {
  float: left;
  // margin-bottom: 5px;
}

.article--meta--label {
  @extend .text--meta_label;
  clear: both;
  // width: 115px;
  width: 100%;
  font-weight: 600;
  text-transform: uppercase;
  color: c("red dark");
}

.article--meta--text {
  @extend .text--meta;
  .article--meta--label + & {
    // width: calc(100% - 115px);
    width: 100%;
    margin-bottom: calc($gutter);
  }
}

.article--meta--logo {
  position: relative;
  float: right;
  max-width: 100%;
  img {
    width: 100%;
    max-width: 200px;
  }
}

.article--actions {
  @include span($columns);
}

.article--meta_banner {
  @include span($columns nest);
}

.article--actions {
  padding-top: 0 !important;
  background: rgba(0, 0, 0, 0.25);
}

.article--meta_banner {
  color: c("white");
  p {
    margin-bottom: 0;
  }
  &.-mo {
    background-color: c("red dark");
    padding-top: 10px;
    padding-bottom: 10px;
  }
  &.-ep {
    background-color: c("blue");
    padding-top: 10px;
    padding-bottom: 10px;
  }
  &.-indeed {
    background-color: #2557a7;
    padding-top: 10px;
    padding-bottom: 10px;
    a{
      color: #FFFFFF;
      text-decoration: underline;
    }
  }
  +.article--section{
    padding-top: 20px;
  }
}

.article--actions_inner {
  float: left;
  clear: both;
  @include breakpoint($tp) {
    float: right;
  }
}


@keyframes loaderRotate {
  0% {
    transform: rotateZ(0deg) scale(1);
  }
  100% {
    transform: rotateZ(360deg) scale(1);
  }
}

.article--loading {
  display: block;
  position: relative;
  float: left;
  clear: both;
  width: 100%;
  height: 100px;
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30px;
    height: 30px;
    margin-left: -15px;
    margin-top: -15px;
    border-radius: 100%;
    border: 3px solid rgba($cLowlight, 0.2);
    border-top: 3px solid rgba($cLowlight, 0.8);
    @include animation(loaderRotate 500ms linear infinite);
  }
  .article--section_inner + & {
    display: none;
  }
  @include breakpoint($tl) {
    width: calc(100% + 60px);
  }
}

#resourceDownload-menu {
  @extend .text--body--very_small;
  max-width: 320px;
}

.article--map{
  padding:0 !important;
  min-height: 420px;
  height: auto;
  background-color: $cSecondary;
  overflow: hidden;
  position: relative;
  .map{
    width:100%;
    height: 100%;
  }
  #map{
    position: relative;
    width: 100%;
    height: 420px;
    background: #D1FCFF;
    overflow: hidden
  }
  .mo-logo{
    position: absolute;
    left: 2rem;
    bottom: 2rem;
    padding: 8px;
    width: 150px;
    height: 150px;
    border-radius: 150px;
    background: #FFFFFF;
    border: 2px solid $cSecondary;
    overflow: hidden;
    z-index: 10;
  }
  .mo-logo img{
    width: 100%;
    height: 100%;
    aspect-ratio: 1;
    object-fit: contain;
  }
}

.memberOrganisations_container{
  .countries {
    display: grid;
    grid-gap: 1rem; /* Consistent gap of 1rem */
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); /* min-width: 100px, max-width: 1fr */
    width: 100%;
    height: 100%;
    margin-top: 2rem;
    margin-bottom: 4rem;
  }

  .country {
      font-size: 1.15rem;
      border: 1px solid #f3f6fb;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: flex-start;
      justify-content: flex-start;
      align-items: center;
      gap: 1rem;
      background: #f3f6fb;
  }
  .country:hover{
      border-color: #1acc9d;
  }
  .country img{
    width: 33%;
    height: auto;
    aspect-ratio: 4/3;
  }
  .regional .country{
    flex-direction: column;
  }
  .regional .country img{
      width: 100%;
      object-fit: contain;
      object-position: center;
      background: #FFFFFF;
  }
  .country .country_name{
      font-family: $type_style--medium;
      font-weight: 300;
      color: #1e1834;
  }
}

.country-modal {
  position: absolute;
  background: #FFFFFF;
  padding: 1rem;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1000;
  pointer-events: none; /* Ensures the modal doesn't interfere with mouse events */
  width: 200px;
}

#modalContent{
  display: flex;
  flex-direction: column;
  font-size: 1.5rem
}

#modalContent .mo_name{
    margin-bottom: 1em;
    font-family: $type_style--bold;
}

/* Optional: Add some transition for smooth appearance and disappearance */
.country-modal {
  visibility: hidden; /* Use visibility instead of display */
  opacity: 0;
  transition: opacity 250ms ease-in-out;
  position: absolute;
  background: #FFFFFF;
  padding: 1.25rem;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 10;
  pointer-events: none;
  width: 300px;
}

.country-modal.show {
  visibility: visible; /* Make modal visible */
  opacity: 1;
}

.article--logo{
  padding:20px;
  height: 360px;
  background-color: $cSecondary;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-origin: content-box;
}

.otip--field_label {
    @include font-size(1.8);
    font-family: $type_style--medium;
    margin: 0 $gutter 0 0;

    // &:first-child {
    //     margin: 0;
    // }
}

.otip--field_content {
  margin-top: calc($gutter);
  margin-bottom: calc($gutter);
  font-family: $type_style--light;
  &.otip--field--type_boolean {
    padding: calc($gutter/2) .75em calc($gutter/2) .5em;
    font-size: .85em;
    display: inline-block;
    border-radius: 999px;
    color: #FFFFFF;
    font-family: $type_style--medium;
    svg {
        margin-right: .5rem;
    }
    &.value_good {
        color: #60742F;
        background: #DDE8C5
    }
    &.value_bad {
        color: #E82624;
        background: #F7B1B1
    }
    &.value_neutral {
        color: #005C64;
        background: #AFD2D5;
    }
  }
  &.otip--field--type_number{
    font-size: 2.5rem;
  }
}

.otip--field {

    &:last-of-type {
        border-bottom: none;
    }
}

.otip--field_extra{
  margin-top: $gutter;
}

.otip--field_details {
    font-family: $type_style--light;
    font-size: 1.66rem;
}

.otip--section {
    border-bottom: 2px solid #e8e8eb;
    margin-bottom: $gutter;

    h3 {
        margin-top: $gutter;
        margin-bottom: $gutter;
    }

    &:last-of-type{
      border-bottom: none;
    }
}

.otip--fields{
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  column-gap: calc($gutter);
  row-gap: calc($gutter);
  margin-top: $gutter;
  margin-bottom: calc($gutter * 2);
  &.no-columns{
    display: block;
  }
  @include breakpoint($tp) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.otip--field_date {
    margin: $gutter 0;
    display: inline-block;
}

.otip--meta_blocks{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: $gutter;
  margin-top: $gutter;
  .otip--meta_block{
    display: flex;
    flex-direction: column;
    .otip--meta--label{
      width: 100%;
      text-transform: uppercase;
      @include font-size(1.1);
      font-family: $type_style--medium;
    }
    .otip--meta--text{
      width: 100%;
      @include font-size(1.2);
      font-family: $type_style--medium;
    }
  }
}
.publications{
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 1fr;
  gap: $gutter;
  .publication{
    padding: $gutter;
    border: 1px solid #e8e8eb;
    h3 {
      margin: 0;
    }
  }
  @include breakpoint($tp) {
    grid-template-columns: repeat(2, 1fr);
  }
}
.collapsible {
    position: relative;
    overflow: hidden;
    transition: max-height 0.5s ease; /* Smooth transition */
    padding-bottom: calc($gutter*3);
}

.collapsible .fade {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 250px; /* Adjust height for gradient fade */
    background: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1));
}

.collapsible .expand-btn {
    position: absolute;
    bottom: $gutter;
    left: 50%;
    transform: translateX(-50%);
    background: $cBlue;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    display: block;
}

.collapsible.expanded {
    max-height: 10000px; /* Large max-height to allow for transition */
}

.collapsible.expanded .fade {
    display: none;
}