$homeTitleBreak : $tp;
.home_title {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @include breakpoint($homeTitleBreak) {
    padding-top: $gutter*12;
    padding-bottom: $gutter*3;
  }
}
  .notification_banner{
  background: $cYellow;
  padding: 0px 20px;
  height: 0%;
  opacity: 0;
  box-shadow: 0px 2px 8px rgba(0,0,0,0.25);
  width: 100%;
  color: #FFFFFF;
  z-index: 1;
  transition: all .5s ease-in;
  &.expanded{
    opacity: 1;
    height: 100%;
    padding: 20px;
    transition: all .5s ease-in;
  }
    .message{
      .message--text{
        float: left;
        @include breakpoint($tl) {
          margin-left: 10px;
          width: 70%;
        }
        span:first-child{
          font-weight: bold;
        }
      }
      .message--options{
        float: left;
        list-style: none;
        margin: 0;
        padding: 0;
        display: inline-block;
        margin-top: 24px;
        @include breakpoint($tl) {
          margin-right: 10px;
          float: right;
        }
        li{
          display: inline-block;
          margin-right: 4px;
          text-decoration: underline;
          padding: 8px 16px;
          @include font-size(1.4);
          font-family: $type_style--medium;
          cursor: pointer;
        }
        .message--options_primary{
          border: 1px solid #FFFFFF;
          text-decoration: none;
        }
        .message--options_primary:hover{
          background: #FFFFFF;
          color: $cYellow ;
        }
        a{
          color: inherit;
        }
      }
    }
  }

.home_title--container {
  position: relative;
  padding-top: $gutter*2;
  background-color: rgba($cSecondary, .75);
  @include breakpoint($homeTitleBreak) {
    background-color: transparent;
  }
}

.home_title--heading,
.home_title--stats,
.home_title--intro {
  display: inline-block;
  vertical-align: bottom;
  direction: ltr;
  width: 100%;
  @include breakpoint($homeTitleBreak) {
    width: 50%;
  }
}

.home_title--heading,
.home_title--intro {
  padding-left: calc($gutter / 2);
  padding-right: calc($gutter / 2);
  @include breakpoint($homeTitleBreak) {
    margin-right: 50%;
  }
}


.home_title--heading {
  @include breakpoint($homeTitleBreak) {
    padding-top: $gutter;
  }
}

.home_title--intro {
  padding-bottom: $gutter;
  background: $cSecondary;
  @include breakpoint($homeTitleBreak) {
    background-color: transparent;
  }
}

.home_title--stats {
  padding-left: calc($gutter / 4);
  padding-right: calc($gutter / 4);
  padding-top: $gutter;
  padding-bottom: $gutter;
  @include breakpoint($homeTitleBreak) {
    position: absolute;
    top: auto; right: calc($gutter / 2); bottom: 0; left: 50%;
    width: auto;
    padding-left: 0;
    padding-right: 0;
  }
}

.home_title--heading_inner,
.home_title--intro_inner {
  @include breakpoint($homeTitleBreak) {
    padding-left: $gutter;
    padding-right: $gutter;
    background-color: rgba($cSecondary, 0.9);
  }
}

.home_title--heading_inner {
  padding-bottom: calc($gutter / 2);
  @include breakpoint($homeTitleBreak) {
    padding-top: $gutter;
  }
  h1{
    margin-top: 1em;
  }
}

.home_title--intro_inner {
  padding-top: calc($gutter / 2);
  padding-bottom: $gutter;
  &.-richText{
    margin-bottom:0;
  }
}

.home_title--stat {
  @include span(calc($columns / 3) nest);
  padding-left: calc($gutter / 4);
  padding-right: calc($gutter / 4);
  @include breakpoint($homeTitleBreak) {
    @include span($columns);
    max-width: 220px;
    padding-left: calc($gutter / 2);
    padding-right: calc($gutter / 2);
    padding-top: $gutter;
    clear: both;
  }
  &:nth-child(1) {
    .home_title--stat_inner {
      background-color: $cHilight;
    }
  }
  &:nth-child(2) {
    .home_title--stat_inner {
      background-color: $cYellow;
    }
  }
  &:nth-child(3) {
    .home_title--stat_inner {
      background-color: c("blue");
    }
  }
  @include breakpoint($ds) {
    @include span(calc($columns / 3));
    max-width: none;
    clear: none;
  }
}

.home_title--stat_inner {
  position: relative;
  padding: $gutter calc($gutter / 2) calc($gutter / 2) calc($gutter / 2);
  color: $cSecondary;
  @include breakpoint($dm) {
    padding: $gutter;
  }
  p {
    margin-bottom: $gutter;
    + p {
      position: absolute;
      top: auto; right: 0; bottom: 0; left: 0;
      padding: calc($gutter / 4);
      margin-bottom: 0;
      text-transform: uppercase;
      @include breakpoint($mp) {
        padding: calc($gutter / 2);
      }
    }
    @include breakpoint($tp) {
      margin-bottom: $gutter;
    }
    @include breakpoint($ds) {
      margin-bottom: $gutter;
    }
  }
}