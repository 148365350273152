.dropzone {
  text-align: center;
  border-style: dashed;
  border-width: $borderWidth;
  border-color: $cLowlight;
  &:hover {
    border-color: $cHilight;
  }
}

.dz-image,
.dropzone .dz-preview.dz-file-preview .dz-image {
  border-radius: 0;
}

.dropzone--ax_button {
  @extend %buttonAsLink;
}