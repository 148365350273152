html {
  font-family: $type_style--light;
  // font-size: 50%;
  // line-height: 1.4;
  font-size: 56%;
  line-height: 1.4;
  color: $cFont;
  -webkit-font-smoothing: antialiased;
  &.-axModeOn {
    color: $cFontAx;
  }
  @include breakpoint($tp) {
    font-size: 62.5%;
    line-height: 1.5;
  }
}

// font-family: $type_style--medium;
// font-family: 'FF DIN Pro'
// font-family: 'FF DIN Pro Bold'

@mixin font-size($fontSize) {
  font-size: $fontSize*10px;
  font-size: $fontSize*1rem;
}

body {
  @include font-size(1.8);
  font-family: $type_style--light;
}

$txtHeadingMargin: 1em 0 1em 0;
$txtLargeHeadingMargin: 2em 0 1em 0;

h1,
.h1 {
  @include font-size(3.815);
  font-family: $type_style--light;
  margin: $txtLargeHeadingMargin;
  font-weight: 100;
  display: block;
}

h2,
.h2 {
  @include font-size(3.052);
  font-family: $type_style--medium;
  margin: $txtLargeHeadingMargin;
  font-weight: 100;
  display: block;
}

h3,
.h3 {
  @include font-size(2.441);
  font-family: $type_style--medium;
  margin: $txtLargeHeadingMargin;
  font-weight: 100;
  display: block;
}

h4,
.h4 {
  @include font-size(1.953);
  font-family: $type_style--medium;
  margin: $txtHeadingMargin;
  font-weight: 100;
  display: block;
}

h5,
.h5 {
  @include font-size(1.563);
  font-family: $type_style--light;
  margin: $txtHeadingMargin;
  font-weight: 100;
  display: block;
}

h6,
.h6 {
  @include font-size(1.25);
  font-family: $type_style--medium;
  text-transform: uppercase;
  margin: $txtHeadingMargin;
  font-weight: 100;
  display: block;
}

p,
.p {
  @include font-size(1.8);
  font-family: $type_style--light;
  margin: 0 0 0.5em 0;
}

.text--home_title {
  @include font-size(3.2);
  line-height: 1.2;
  font-family: $type_style--bold;
}

.text--stat {
  @include font-size(5);
  font-family: $type_style--medium;
  @include breakpoint($ds) {
    @include font-size(6);
  }
}

.text--body--large {
  @include font-size(2.0);
}

.text--body--medium {
  @include font-size(1.75);
  font-family: $type_style--light;
  margin: 0 0 0.5em 0;
}

.text--body--small {
  @include font-size(1.4);
  font-family: $type_style--medium;
}

.text--span--small {
  @include font-size(1.4);
}

#membership_included{
  margin-top: 1em;
  display: block;
}

.text--button {
  @include font-size(1.6);
  font-family: $type_style--medium;
}

.text--instruction {
  @include font-size(1.75);
  display: block;
  margin-top: -.5em;
  margin-bottom: 1em;
}

.text--meta {
  @include font-size(1.5);
  font-family: $type_style--medium;
}

.text--meta_label {
  @include font-size(1.2);
  font-family: $type_style--medium;
}

.text--body--very_small {
  @include font-size(1.2);
  font-family: $type_style--medium;
}


.-axText {
  position: absolute !important;
  height: 1px;
  width: 1px;
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
}

table {
  .article & {
    width: 100%;
    text-align: left;
    margin-bottom: $gutter;
    th {
      background-color: $cGhost;
    }
    th,
    td {
      padding: calc($gutter / 2);
      border: $borderWidth solid $cDull;
    }
  }
}



.-looseLastComma {
  > span {
    &:after {
      content: ',';
    }
    &:last-child {
      &:after {
        content: '';
      }
    }
  }
}


.-richText {
  h1 {}
  h2 {}
  h3 {
    @extend .h4;
  }
  h4 {
    @extend .h6;
  }
  ul {
    margin-top: 0;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p:first-child {
    margin-top: $gutter;
  }
  &:last-child {
    margin-bottom: $gutter*2;
  }
  img{
    max-width: 100%;
  }
}

body.educationProgrammes{
  .-richText {
    *{
      font-family: inherit !important;
      font-size: inherit !important;
    }
  }
}
