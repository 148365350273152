// PAGE TITLE MODULE
// ===========

.page_title {
  position: relative;
  width: 100%;
  color: $cSecondary;
  background-color: $cLowlight;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: 0;
  }
  padding-top: $gutter*4;
  &.-backgroundImage {
    padding-top: 0;
  }
}

// CONTAINER
.page_title--container {
  position: relative;
  .-backgroundImage & {
    height: $pageTitleBackgroundImageHeight;
    @include breakpoint($mw) {
      height: $pageTitleBackgroundImageHeightMw;
    }
    @include breakpoint($tp) {
      height: $pageTitleBackgroundImageHeightTp;
    }
    @include breakpoint($tl) {
      height: $pageTitleBackgroundImageHeightTl;
    }
    @include breakpoint($ds) {
      height: $pageTitleBackgroundImageHeightDs;
    }
  }
}


// ARTICLE (LEFT CONTENT)
.page_title--article {
  background-color: rgba($cDefault, 0.9);
  padding-right: calc($gutter / 2);
  .-backgroundImage & {
    // @extend %articleTextSpaceLeft;
    position: absolute;
    top: auto; right: 0; bottom: 0; left: 0;
  }
  @include breakpoint($mw) {
    padding-left: calc($gutter / 2);
    .-backgroundImage & {
      background-color: transparent;
    }
  }
  @include breakpoint($tp) {
    .-backgroundImage & {
      padding: 0 $gutter;
    }
  }
  @include breakpoint($sidebarBreak) {
    width: calc(100% - #{$sidebarWidth});
  }
}

// BOTH

.page_title--article_section {
  @include span($columns nest);
  @extend %articleTextSpaceLeft;
  padding-top: $gutter;
  padding-bottom: $gutter;
  .-backgroundImage & {
    color: $cPrimary;
    @include breakpoint($mw) {
      background-color: rgba($cDefault, 0.9);
    }
  }
  @include breakpoint($pageBodyTextIndentBreak) {
    padding-top: calc($gutter / 2);
    // padding-bottom: calc($gutter / 2);
    // padding-left: $textIndent;
    padding-right: 0;
  }
  @include breakpoint($pageTitleShareBreak) {
    @include font-size(0);
    direction: rtl;
    > * {
      direction: ltr;
    }
  }
}

.page_title--article_section--title,
.page_title--article_section--button {
  display: block;
  width: 100%;
  @include breakpoint($pageTitleShareBreak) {
    @include font-size(1.8);
    display: inline-block;
    vertical-align: bottom;
  }
}

.page_title--article_section--title {
  width: calc(100% - #{$articleSpaceRight});
  .-backgroundImage & {
    width: calc(100% - #{$articleSpaceRight + $gutter});
  }
}

.page_title--article_section--button {
  position: absolute;
  top: auto; right: auto; bottom: 100%; left: $gutter;
  width: $articleSpaceRight;
  padding-bottom: calc($gutter / 2);
  .-backgroundImage & {
    width: $articleSpaceRight + $gutter;
    padding-right: $gutter;
  }
  button {
    width: $articleSpaceRight;
    border: $borderWidth solid $cSecondary;
    background-color: transparent;
    color: $cSecondary;
    &:hover {
      background-color: $cSecondary;
      color: $cLowlight;
    }
    .-backgroundImage & {
      border: $borderWidth solid $cDull;
      @include breakpoint($tp) {
        color: $cPrimary;
      }
    }
  }
  @include breakpoint($tp) {
    position: relative;
    top: auto; right: auto; bottom: auto; left: auto;
  }
  @include breakpoint($pageTitleShareBreak) {
    text-align: right;
  }
}

.page_title--article_section--title--backlink {
  color: $cSecondary;
}




