.resources_module {
  padding: $gutter*2 0 $gutter 0;
  background-color: $cPrimary;
  &.-dull {
    background-color: c("grey");
  }
}

.resources_module--header {
  @include span($columns);
  color: $cSecondary;
  .-dull & {
    color: $cPrimary;
  }
  > * {
    margin-top: 0;
  }
}