.article--callToAction {
  @include span($columns nest);
  padding-top: $gutter;
  padding-bottom: $gutter;
  border-top: $borderWidth solid mix($cPrimary, $cSecondary, 30%);
  border-bottom: $borderWidth solid mix($cPrimary, $cSecondary, 30%);
  background-color: $cGhost;
  &:first-child {
    border-top: 0;
  }
  &:last-child {
    border-bottom: 0;
  }
}

.article--callToAction_inner {
  @include span($columns nest);
  @extend %articleTextSpaceLeft;
  @extend %articleTextSpaceRight;
  position: relative;
}
