.adWide,
.adSmall {
  position: relative;
  width: 100%;
  text-align: left;
  color: rgba(30, 24, 52, .5);
}

.adWide {
  display: none;
  @include breakpoint($mw) {
    display: block;
  }
}

.adSmall {
  display: inline-block;
  @include breakpoint($mw) {
    display: none;
  }
}

.adWide--inner {
  @include span($columns);
  text-align: center;
}

.adWide--wrapper {
  display: inline-block;
  margin: auto;
  padding: $gutter 0;
  text-align: left;
  img {
    width: 800px;
    max-width: 100%;
    height: auto;
    .adSmall & {
      width: 300px;
    }
  }
  iframe{
    max-width: 100%;
  }
}
