.listing_pagination {
  @include span($columns nest);
  ul {
    @include clearList;
  }
  li {
    @include span(calc($columns / 3) nest);
    min-height: 1px;
    &.-disabled {
      a {
        display: none;
      }
    }
    a {
      color: $cLowlight;
      &:hover,
      &:focus,
      &:active {
        color: $cHilight;
      }
    }
    &:nth-child(1) {
      text-align: left;
    }
    &:nth-child(2) {
      text-align: center;
    }
    &:nth-child(3) {
      text-align: right;
    }
  }
}