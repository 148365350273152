// PAGE BODY MODULE
// ================

.page_body {
  padding-bottom: $gutter*2;
  // h1,
  // h2,
  // h3,
  // h4,
  // h5 {
  //   margin: 0;
  // }
}

.page_body--background {
  position: absolute;
  top: 0; right: 0; bottom: auto; left: 0; z-index: -1;
  height: $pageBodyBackgroundImageHeight;
  background-color: $cLowlight;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  @include breakpoint($mw) {
    height: $pageBodyBackgroundImageHeightMw;
  }
  @include breakpoint($tp) {
    height: $pageBodyBackgroundImageHeightTp;
  }
  @include breakpoint($tl) {
    height: $pageBodyBackgroundImageHeightTl;
  }
  @include breakpoint($ds) {
    height: $pageBodyBackgroundImageHeightDs;
  }
}

.-backgroundImage+.page_body>.page_body--background{
    display:none;
}