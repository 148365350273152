// CENTER VERTICAL
@mixin vAlign($elem, $hcenter: center, $vcenter: middle) {
  @include inlineFix;
  @if $hcenter == right {
    text-align: right;
    &:before {
      content: '';
      display: inline-block;
      width: 1px;
      height: 100%;
      vertical-align: middle;
      margin-right: 1px; /* Adjusts for spacing */
    }
  }
  @else {
    @if $hcenter == center {
      text-align: center;
    }
    @if $hcenter == left {
      text-align: left;
    }
    &:after {
      content: '';
      display: inline-block;
      width: 1px;
      height: 100%;
      vertical-align: middle;
      margin-right: 1px; /* Adjusts for spacing */
    }
  }
  > #{$elem} {
    display: inline-block;
    vertical-align: $vcenter;
    max-width: calc(100% - 4px);
  }
}