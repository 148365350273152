$susy: (
  columns               : $columns,
  gutter-override       : $gutter,
  gutter-position       : inside,
  container             : $containerMax,
  math                  : fluid,
  output                : float,
  global-box-sizing     : border-box,
  debug: (
    image: hide,
    color: orange,
  )
);

html {
  // background-color: $cDefault;
  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }
}

body {
  color: $cFont;
  &.-axModeOn {
    color: $cFontAx;
  }
}


.container,
div[class$="--container"] {
  @include container;
  @include breakpoint($tp) {
    padding: 0 calc($gutter / 2);
  }
}