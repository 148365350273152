// SELECT DROPDOWNS
.input--select {
  label {
    > .icon {
      position: absolute;
      top: auto; right: 0.75em; bottom: 0.75em; left: auto;
    }
  }
}


select {
  @extend %focusOutline;
  @extend %inputStyle;
  @extend %inputStyleAx;
  box-sizing: border-box;
  padding-right: calc(20px + 1em);
  padding: $inputPadding;
  &.-thin {
    padding: $inputPaddingThin;
  }
}

.-overflow--select, ul.ui-menu{
  max-height: 50vh;
}


// JQUERY UI OVERRIDES
// select menu button
.ui-selectmenu-button.ui-button {
  @extend %inputStyle;
  @extend %inputStyleAx;
  width: 100%;
  padding: $inputPadding;
  line-height: $inputLineHeight;
  select.-thin + & {
    padding: $inputPaddingThin;
  }
}

.ui-selectmenu-menu {
  background-color: $cSecondary;
  border: none;
  box-shadow: $boxShadow;
  border-radius: $borderRadius;
  .ui-menu .ui-menu-item-wrapper {
    padding: $inputPadding;
    &.ui-state-focus,
    &.ui-state-active {
      margin: 0;
      background-color: $cDefault;
    }
  }
}

// select menu icon change
.ui-selectmenu-icon {
  text-indent: 0px;
  overflow: visible;
  .-up {
    display: none;
  }
  .ui-selectmenu-button-open & {
    .-up {
      display: inline-block;
    }
    .-down {
      display: none;
    }
  }
}

.ui-state-disabled.ui-menu-item {
  color: $cDull;
}

// // select menu dropdown
// .ui-widget.ui-widget-content {
//   border: none;
//   box-shadow: $boxShadow;
//   border-radius: $borderRadius;
//   margin-top: 10px;
// }

// // select menu dropdown items
// .ui-state-active,
// .ui-widget-content .ui-state-active {
//   border: none;
//   background-color: $cSelectItemHover;
//   color: $cSelectTxt;
//   .-axModeOn & {
//     background-color: $cSelectItemHoverAx;
//     color: $cSelectItemHoverTxtAx;
//   }
// }
// .ui-menu .ui-menu-item-wrapper {
//   padding: $inputPadding;
// }
// .ui-widget-content {
//   color: inherit;
// }

// // select menu category titles
// .ui-selectmenu-menu .ui-menu .ui-selectmenu-optgroup {
//   color: $cSelectTitleTxt;
//   .-axModeOn & {
//     color: $cSelectTitleTxtAx;
//   }
// }

// // jQuery UI Icon clear backgrounds
// .ui-button .ui-icon {
//   background-image: none !important;
// }

// // jQuery UI svg icon fix





// .ui-corner-all,
// .ui-corner-top,
// .ui-corner-left,
// .ui-corner-tl {
//   border-top-left-radius: $borderRadius;
// }

// .ui-corner-all,
// .ui-corner-top,
// .ui-corner-right,
// .ui-corner-tr {
//   border-top-right-radius: $borderRadius;
// }

// .ui-corner-all,
// .ui-corner-bottom,
// .ui-corner-left,
// .ui-corner-bl {
//   border-bottom-left-radius: $borderRadius;
// }

// .ui-corner-all,
// .ui-corner-bottom,
// .ui-corner-right,
// .ui-corner-br {
//   border-bottom-right-radius: $borderRadius;
// }

// .ui-state-hover,
// .ui-widget-content .ui-state-hover,
// .ui-widget-header .ui-state-hover,
// .ui-state-focus,
// .ui-widget-content .ui-state-focus,
// .ui-widget-header .ui-state-focus,
// .ui-button:hover, .ui-button:focus,
// .ui-state-default,
// .ui-widget-content .ui-state-default,
// .ui-widget-header .ui-state-default,
// .ui-button,
// html .ui-button.ui-state-disabled:hover,
// html .ui-button.ui-state-disabled:active {
//   border-width: $borderWidth;
//   border-style: solid;
//   border-color: $cInputBorder;
// }



