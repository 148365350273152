// GENERAL
// =======
  // borders
  $borderRadius             : 2px;
  $borderWidth              : 1px;

  // outline
  $hoverOutline             : 5px auto $cHilight;
  $focusOutline             : 5px auto $cHilight;
  $focusOutlineAx           : 5px solid $cHilight;

  // shadow
  $boxShadowLight           : 0px 2px 15px -8px $cPrimary;
  $boxShadow                : 0px 2px 10px 0px rgba($cPrimary, 0.3);

  // text
  $textIndent               : $gutter;



// INPUTS
// ======
  $inputPaddingTop          : 11px;
  $inputPaddingBottom       : 9px;
  $inputPaddingLeft         : calc($gutter / 1.5);
  $inputPaddingRight        : calc($gutter / 1.5);
  $inputPadding             : $inputPaddingTop $inputPaddingRight $inputPaddingBottom $inputPaddingLeft;
  
  $inputPaddingThin         : 8px $inputPaddingRight 8px $inputPaddingLeft;


  $inputMarginBottom        : calc($gutter / 3);

  $inputLineHeight          : 25px;


  $buttonPadding            : $inputPadding;



// MAIN
// =======



// PAGE BODY
// =========
  $pageBodyTextIndentBreak          : $mw;
  $pageBodyBackgroundImageHeight    : 200px;
  $pageBodyBackgroundImageHeightMw  : 260px;
  $pageBodyBackgroundImageHeightTp  : 280px;
  $pageBodyBackgroundImageHeightTl  : 300px;
  $pageBodyBackgroundImageHeightDs  : 300px;



// PAGE TITLE
// ==========
  $pageTitleDefaultHeight            : $pageBodyBackgroundImageHeight*0.3;
  $pageTitleShareBreak               : $tp;

  $pageTitleBackgroundRatio          : 1.5;
  $pageTitleBackgroundImageHeight    : $pageBodyBackgroundImageHeight*$pageTitleBackgroundRatio;
  $pageTitleBackgroundImageHeightMw  : $pageBodyBackgroundImageHeightMw*$pageTitleBackgroundRatio;
  $pageTitleBackgroundImageHeightTp  : $pageBodyBackgroundImageHeightTp*$pageTitleBackgroundRatio;
  $pageTitleBackgroundImageHeightTl  : $pageBodyBackgroundImageHeightTl*$pageTitleBackgroundRatio;
  $pageTitleBackgroundImageHeightDs  : $pageBodyBackgroundImageHeightDs*$pageTitleBackgroundRatio;



// ARTICLE
// =======
  $articleTopImageHeight       : $pageBodyBackgroundImageHeight*0.7;
  $articleSpaceRight           : 150px;




// CARDS
// =====
  $cardSideSize       : 70px;
  $cardSize1          : "-small";
  $cardSize2          : "-medium";
  $cardSize3          : "-large";

  $cardContentWidthMw : 0.5;
  $cardFigureWidthMw  : 1 - $cardContentWidthMw;
  $cardContentWidthTp : 0.6;
  $cardFigureWidthTp  : 1 - $cardContentWidthTp;
  $cardContentWidthTl : 0.6;
  $cardFigureWidthTl  : 1 - $cardContentWidthTl;
  $cardContentWidthDs : 0.6;
  $cardFigureWidthDs  : 1 - $cardContentWidthDs;





%moduleSpace {
  padding: $gutter 0;
}

%articleTextSpaceLeft {
  padding-left: $gutter;
  @include breakpoint($mw) {
    padding-left: $gutter;
  }
  @include breakpoint($tp) {
    padding-left: $gutter*2;
  }
  .article--section & {
    padding-left: 0 !important;
  }
}

%articleTextSpaceRight {
  padding-right: $gutter;
  // @include breakpoint($tp) {
  //   padding-right: $articleSpaceRight;
  // }
  // @include breakpoint($tl) {
  //   padding-right: $gutter;
  // }
  // @include breakpoint($ds) {
  //   padding-right: $articleSpaceRight;
  // }
  padding-right: $gutter;
  @include breakpoint($mw) {
    padding-right: $gutter;
  }
  @include breakpoint($tp) {
    padding-right: $gutter*2;
  }
  // @include breakpoint($tl) {
  //   padding-right: 100px;
  // }
  .article--section & {
    padding-right: 0 !important;
  }
}

@mixin hover {
  outline: $hoverOutline;
  border-color: $cHilight;
  .-axModeOn & {
    outline: $focusOutlineAx;
  }
}

@mixin focus {
  outline: $focusOutline;
  .-axModeOn & {
    outline: $focusOutlineAx;
  }
}

%focusOutline {
  &:focus {
    outline: $focusOutline;
  }
}

%focusOutlineAx {
  .-axModeOn & {
    &:hover,
    &:focus {
      outline: $focusOutlineAx;
    }
  }
}