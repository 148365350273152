.input--datepicker {
  &.-jquery_ui {
  }
}

.ui-datepicker {
  background-color: c("white");
  box-shadow: $boxShadow;
  border-radius: $borderRadius;
}

.ui-datepicker td span,
.ui-datepicker td a {
  text-align: center;
  &:hover {
    background-color: $cHilight;
    color: $cSecondary;
  }
}

.ui-datepicker-prev,
.ui-datepicker-next {
  top: 0 !important;
  line-height: 2em;
  text-align: center;
}

.ui-datepicker-prev {
  left: 0 !important;
}

.ui-datepicker-next {
  right: 0 !important;
}
