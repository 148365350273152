$accordionIconSize : 30px;
.accordion {
  @include span($columns nest);
  padding-left: $accordionIconSize+$gutter;
  // .ui-accordion-header-icon {
  //   display: none;
  // }
}

.accordion--title,
.accordion--desc {
  @include span($columns nest);
  background-color: $cGhost;
  color: $cInfo;
}

.accordion--title.ui-accordion-header {
  margin-top: calc($gutter / 2);
  padding: calc($gutter/2) $gutter;
  &:first-child {
    margin-top: 0;
  }
}


.attendees--item_image {
  float: left;
}

.accordion--icon {
  float: right;
}

.ui-accordion-header-icon {
  display: block;
  position: absolute;
  top: 0; right: calc(100% + #{calc($gutter / 2)}); bottom: 0; left: auto;
  width: $accordionIconSize+$gutter; min-height: $accordionIconSize+$gutter;
  margin-top: 0;
  outline: none !important;
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 50%; right: auto; bottom: auto; left: 50%;
    margin-left: calc($accordionIconSize / 2 * -1);
    width: $accordionIconSize; height: $borderWidth;
    background-color: $cInfo;
    transition: all 0.25s ease;
  }
  &:after {
    transform: rotate(90deg);
  }
  .ui-accordion-header-active & {
    &:before {
      transform: rotate(180deg);
    }
    &:after {
      transform: rotate(180deg);
    }
  }
}

.accordion--desc {
  margin: 0;
  &.ui-accordion-content {
    .ui-accordion & {
      padding: 0;
    }
  }
}


.accordion--desc_inner {
  display: block;
  padding: 0 $gutter $gutter $gutter;
}


// .accordion--icon_image {
//   display: inline-block;
//   vertical-align: text-bottom;
//   position: relative;
//   width: $accordionIconSize; height: $accordionIconSize;
//   &:before,
//   &:after {
//     content: '';
//     position: absolute;
//     top: 50%; right: 50%; bottom: auto; left: auto;
//     width: $accordionIconSize*0.5;
//     height: $borderWidth;
//     margin-top: -1;
//     margin-right: -$accordionIconSize/4;
//     background-color: $cDefault;
//     transition: transform 0.25s ease;
//   }
//   &:after {
//     transform: rotate(-90deg);
//     .ui-state-active & {
//       transform: rotate(0deg);
//     }
//   }
//   &:before {
//     .ui-state-active & {
//       transform: rotate(180deg);
//     }
//   }
// }
