$columns                   : 24 !default;
$gutter                    : 20px !default;
$advertWidth               : 300px;
$sidebarWidth              : $advertWidth + ($gutter*4);
$sidebarBreak              : $ds;
$containerMax              : 1440px;
// $article--tp               : 17 !default;
// $sidebar--tp               : 7 !default;
// $section_header--tl        : 5 of 17 !default;
// $section_body--tl          : 12 of 17 !default;
