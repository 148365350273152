.breadcrumb {
  // display: none;
  // border-bottom: $borderWidth solid c("grey medium");
  background-color: $cLowlight;
}

.breadcrumb--inner {
  @include span(24);
}

.breadcrumb--wrapper {
  @include breakpoint($tl) {
    padding-left: $textIndent;
    // @include span(19);
    // @include push(5);
  }
}

.breadcrumb--nav {
  padding: calc($gutter / 2) 0;
  a,
  p {
    @include font-size(1.4);
    color: $cSecondary;
  }
}