// RADIO BUTTONS
input[type="radio"] {
}

// CHECKBOXES
input[type="checkbox"] {
}

.input--radio,
.input--checkbox {
  display: block;
  margin-right: $inputMarginBottom;
  margin-bottom: $inputMarginBottom;
  > label {
    @extend %inputStyleAx;
    display: inline-block;
    vertical-align: middle;
    background-color: $cInputRadioBg;
    border-radius: $borderRadius;
    border: $borderWidth solid $cInputBorder;
    padding: $inputPaddingTop $inputPaddingRight $inputPaddingBottom $inputPaddingLeft*3;
    .-axModeOn & {
      background-color: transparent;
    }
    > input,
    > span {
      display: inline-block;
    }
    > input {
      position: absolute;
      top: $inputPaddingTop*1.3; left: $inputPaddingLeft;
    }
    > span {
    }
    &:hover {
      @include hover;
      background-color: $cInputRadioBgH;
      cursor: pointer;
    }
  }
  &.-focus {
    > label {
      @include focus;
    }
  }
}