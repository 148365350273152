a {
  @extend %focusOutlineAx;
  text-decoration: none;
  word-break: break-word;
  color: $cLinks;
  &:hover,
  &:active {
    color: $cLinksAx;
  }
  .-axModeOn & {
    color: $cLinksAx;
    &:hover,
    &:focus {
      background-color: $cHilight;
      color: $cLinksAx;
    }
  }
}

%buttonAsLink {
  padding: 0;
  border: none;
  background: none;
  color: inherit;
  &:hover,
  &:focus,
  &:active {
    background: none;
    color: $cLinksAx;
  }
  .-axModeOn & {
    background: none;
    color: $cLinksAx;
    &:hover,
    &:focus {
      background-color: $cHilight;
      color: $cLinksAx;
    }
  }
}

%aLink--focus {
  &:hover,
  &:active {
    color: $cLinksAx;
  }
  .-axModeOn & {
    color: $cLinksAx;
    &:hover,
    &:focus {
      background-color: $cHilight;
      color: $cLinksAx;
    }
  }
}