.reps,
.rep,
.rep--current,
.rep--remove,
.rep--add,
.rep--form {
  @include span($columns nest);
}

.rep {
  padding: 25px 0 $gutter 0;
  border-bottom: $borderWidth solid $cDull;
  &:last-child {
    border-bottom: 0;
  }
  p {
    margin-bottom: 0;
  }
}

.rep--remove {
  p {
    float: left;
    width: calc(100% - 150px);
  }
  .reps--remove--buttons {
    float: right;
    width: 150px;
    text-align: right;
  }
  button {
    display: inline-block;
  }
}

.reps--button--remove,
.reps--button--add {
  @extend %clearButtonStyle;
  display: inline-block;
  color: $cHilight;
  &:hover,
  &:active,
  &:focus {
    color: c('yellow');
  }
}