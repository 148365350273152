.card--inner,
.card--content,
.card--content_body,
.card--content_body--info,
.card--content_footer,
.card--content_body--info {
  @include span($columns nest);
}

.card {
  @include span($columns);
  position: relative;
  padding-bottom: $gutter;
  .#{$cardSize1} & {
    display: none;
    &:nth-child(1) {
      display: block;
    }
  }
  @include breakpoint($mm) {
    .#{$cardSize1} & {
      @include span($columns);
      display: none;
      &:nth-child(1) {
        display: block;
      }
    }
  }
  @include breakpoint($ml) {
    .#{$cardSize1} & {
      @include span(calc($columns / 2));
      &:nth-child(2) {
        display: block;
      }
    }
    .#{$cardSize2} & {
      @include span(calc($columns / 2));
    }
  }
  @include breakpoint($mw) {
    .#{$cardSize1} & {
      @include span(calc($columns / 3));
      &:nth-child(3) {
        display: block;
      }
    }
    .#{$cardSize2} & {
      @include span(calc($columns / 2));
    }
  }
  @include breakpoint($tp) {
    .#{$cardSize1} & {
      @include span(calc($columns / 4));
      &:nth-child(4) {
        display: block;
      }
    }
    .#{$cardSize2} & {
      @include span(calc($columns / 2));
    }
  }
  @include breakpoint($tm) {
    .#{$cardSize1} & {
      @include span(calc($columns / 4));
      &:nth-child(4) {
        display: block;
      }
    }
    .#{$cardSize2} & {
      @include span(calc($columns / 3));
    }
  }
  @include breakpoint($tl) {
    @include span(calc($columns / 2));
    .#{$cardSize1} & {
      @include span(calc($columns / 4));
      // &:nth-child(5) {
      //   display: block;
      // }
    }
    .#{$cardSize2} & {
      @include span(calc($columns / 2));
    }
    .#{$cardSize3} & {
      @include span($columns);
    }
  }
  @include breakpoint($ds) {
    .#{$cardSize1} & {
      @include span(calc($columns / 5));
      &:nth-child(5) {
        display: block;
      }
    }
    .#{$cardSize2} & {
      @include span(calc($columns / 2));
    }
  }
  @include breakpoint($dm) {
    .#{$cardSize1} & {
      @include span(calc($columns / 6));
      &:nth-child(6) {
        display: block;
      }
    }
    .#{$cardSize2} & {
      @include span(calc($columns / 3));
    }
  }
}


.card--inner {
  display: block;
  position: relative;
  border: $borderWidth solid $cGhost;
  background-color: $cGhost;
  color: $cPrimary;
  .classifieds_module & {
    border: $borderWidth solid $cGhost;
    background-color: $cGhost;
  }
  .resources_module & {
    border: $borderWidth solid $cBlue;
    background-color: $cBlue;
    color: $cSecondary;
  }
  .resources_module.-dull & {
    border: $borderWidth solid $cSecondary;
    background-color: $cSecondary;
    color: $cPrimary;
  }
  &.featured.-theme1{
    border: $borderWidth solid $cGreen;
    box-shadow: 0 0 15px rgba($cGreen, .35)
  }
  &.featured.-theme2{
    border: $borderWidth solid $cYellow;
    box-shadow: 0 0 15px rgba($cYellow, .35)
  }
  &.featured.-theme3{
    border: $borderWidth solid $cLowlight;
    box-shadow: 0 0 15px rgba($cLowlight, .35)
  }
  &:hover {
    color: $cHilight;
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: -1px;
      bottom: -1px;
      left: -1px;
      right: -1px;
      border: $borderWidth solid $cHilight;
    }
    .resources_module.-dull & {
      color: $cHilight;
      &:after {
        border: $borderWidth solid $cHilight;
      }
    }
  }
  &.featured .card--content_heading--text{
    padding: 4px 8px;
    background: $cGreen;
    color: $cSecondary;
    display: inline;
  }
  &.featured.-theme1 .card--content_heading--text{
    background: $cGreen;
  }
  &.featured.-theme2 .card--content_heading--text{
    background: $cYellow;
  }
  &.featured.-theme3 .card--content_heading--text{
    background: $cLowlight;
  }
}


.card--figure {
  @include span($columns);
  position: relative;
  height: 200px;
  .-normal & {
    @include breakpoint($mw) {
      float: none;
      position: absolute;
      top: 0; right: span($columns*$cardContentWidthMw); bottom: 0; left: 0;
      width: auto; height: auto;
    }
    @include breakpoint($tp) {
      right: span($columns*$cardContentWidthTp);
    }
    @include breakpoint($tl) {
      right: span($columns*$cardContentWidthTl);
    }
    @include breakpoint($ds) {
      right: span($columns*$cardContentWidthDs);
    }
  }
}

.card--figure_image {
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #ffffff;
  border: 10px solid #F3F6FB;
}

.card--content {
  padding: $gutter;
  .card--figure + & {
    .-normal & {
      @include breakpoint($mw) {
        @include span($columns*$cardContentWidthMw nest);
        @include push($columns*$cardFigureWidthMw);
      }
      @include breakpoint($tp) {
        @include span($columns*$cardContentWidthTp nest);
        @include push($columns*$cardFigureWidthTp);
      }
      @include breakpoint($tl) {
        @include span($columns*$cardContentWidthTl nest);
        @include push($columns*$cardFigureWidthTl);
      }
      @include breakpoint($ds) {
        @include span($columns*$cardContentWidthDs nest);
        @include push($columns*$cardFigureWidthDs);
      }
    }
  }
}

.card--content_body--type {
  display: none;
  float: left;
  position: relative;
  max-width: 100%;
  margin: 0 0 calc($gutter / 2) 0;
  &:before {
    content: '';
    position: absolute;
    top: -$gutter; right: -$gutter; bottom: 0; left: -$gutter;
    background-color: $cGreen;
    z-index: 0;
  }
  &.-theme2 {
    &:before {
      background-color: $cYellow;
    }
  }
  &.-theme3 {
    &:before {
      background-color: $cLowlight;
    }
  }
  p {
    position: relative;
    z-index: 1;
    color: $cSecondary;
    text-transform: uppercase;
    white-space: nowrap;
  }
  .classifieds_module & {
    display: block;
  }
}

.card--content_body--info,
.card--content_body--side,
.card--content_footer--info,
.card--content_footer--side {
  @include font-size(1.8);
  display: inline-block;
  vertical-align: bottom;
}

.card--content_body--info {
  clear: both;
  width: calc(100% - #{$cardSideSize + 2px});
  .#{$cardSize1} & {
    width: calc(100% - 1px);
  }
}

.card--content_footer--info {
  clear: both;
  width: calc(100% - #{$cardSideSize + 2px});
  .#{$cardSize1} & {
    width: calc(100% - 1px);
  }
}

.card--content_body--side,
.card--content_footer--side {
  text-align: right;
  width: $cardSideSize;
  .#{$cardSize1} & {
    display: none;
  }
}

.card--content_body--info--text {
  @extend .h3;
  @include font-size(1.9);
  margin-top: .5rem;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  height: 9rem;
  max-height: 9rem;
  line-height: 3rem;
  .-small & {
    @extend .text--body--medium;
  }
  h3{
    margin: 0;
  }
}

.card--content_body--side {
  font-family: $type_style--medium;
  text-align: center;
  line-height: 12px;
  span {
    @include font-size(1.1);
    line-height: 0.5em;
  }
}

.card--content_footer {
  height: 40px;
  font-size: 0;
  &:before {
    content: '';
    display: inline-block;
    vertical-align: bottom;
    width: 1px;
    height: 100%;
  }
  p {
    margin-bottom: 0;
  }
  .-small & {
    height: 100px;
  }
}

.card--content_footer--side {
  font-family: $type_style--medium;
  white-space: nowrap;
  text-indent: -30px;
}

.card--content_footer--text {
  @extend .h6;
  .-small & {
    @extend .text--body--very_small;
  }
  .ep-approved{
    color: c("green");
  }
  .ep-discontinued{
    color: c("red");
  }
}

.card--content_heading--text {
  @extend .h6;
  margin: 0;
  .-small & {
    max-height: 2rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    @extend .text--body--very_small;
  }
}
.-small{
  .card--content_heading--text{
    display: none !important;
  }
}
