$tStepBreak : $tl;
.tab_steps,
.tab_steps--nav,
.tab_steps--tabs {
  @include span($columns nest);
}

.tab_steps--tab {
  width: 100%;
  .-js & {
    display: none;
  }
}

.tab_steps--link {
  cursor: pointer;
  .-active & {
    color: $cHilight;
  }
  .-error & {
    color: red;
  }
}

.tab_steps--nav_trigger,
.tab_steps--link {
  padding: 0;
  background: none;
  border: 0;
  color: $cPrimary;
  &:hover,
  &:focus {
    color: $cHilight;
    background: none;
  }
}

.tab_steps--nav {
  position: relative;
  // border-bottom: $borderWidth solid $cDefault;
  &:before {
    content: '';
    position: absolute;
    top: -20px; right: -$gutter; bottom: 0; left: -$gutter; z-index: 0;
    background-color: $cGhost;
    @include breakpoint($tp) {
      right: -$gutter*2; left: -$gutter*2;
    }
    @include breakpoint($tl) {
      right: -100px;
    }
  }
  > * {
    position: relative;
    z-index: 1;
  }
  ol {
    // display: none;
    // @include breakpoint($tStepBreak) {
      @include span($columns nest);
      display: block;
      list-style-position: inside;
      padding: 0;
    // }
    @include breakpoint($ds) {
      li {
        float: left;
        clear: left;
        // &.-right {
        //   float: none;
        //   clear: none;
        //   margin-left: 50%;
        // }
      }
    }
  }
}

.tab_steps--nav_li {
  @extend .text--body--small;
  &.-active {
    color: $cHilight;
  }
}

.tab_steps--nav_title {
  display: none;
  @include breakpoint($tStepBreak) {
    display: block;
  }
}

.tab_steps--nav_trigger {
  display: none;
  // display: inline-block;
  // @include breakpoint($tStepBreak) {
  // }
}

.tab_steps--tabs {
  padding-top: $gutter;
}