.listing,
.listing--header {
  @include span($columns nest);
}

.listing--header {
  padding: $gutter*2 0 $gutter 0;
  > * {
    margin-top: 0;
  }
  .listing_module & {
    padding: 0 calc($gutter / 2) 0 calc($gutter / 2);
  }
}

.listing--body {
  float: left;
  width: calc(100% + #{$gutter});
  margin-left: -calc($gutter * 0.5);
  margin-right: -calc($gutter * 0.5);
}

.listing--body_half {
  @include span($columns nest);
  @include breakpoint($tp) {
    @include span($columns*0.5 nest);
  }
}

// ARTICLE LISTING
.page_body--article_listing {
  @include span($columns);
  background-color: $cSecondary;
}

// MODULE
.listing_module {
  padding-top: $gutter*2;
  padding-bottom: $gutter;
  background-color: $cDefault;
}

#listingResults{
  .resetSearch{
    cursor: pointer;
  }
}

#orderedEntries, .orderedEntries {
  float: left;
  width: 100%;
  #resultsHint{
    background: $cGhost;
    float: left;
    padding: calc($gutter/2);
    width: calc(100% - $gutter);
    text-align: center;
    margin: calc($gutter/2);
    @include font-size(1.4);
    font-family: $type_style--medium;
  }
}

.listing--body_controls {
  float: left;
  width: 100%;
  padding: 10px;
  font-family: $type_style--medium;
  
  p {
    display: inline-block;
    color: mix($cPrimary, white, 50%);
    padding: 1em;
    font-family: $type_style--medium;
  }
  a {
    display: inline-block;
    color: mix($cPrimary, white, 50%);
    padding: 1em;
    font-family: $type_style--medium;
  }
  span{
    padding: 1em;
  }

  .paginate-button {
    cursor: pointer;
    &:hover {
      color: $cHilight;
      background: $cGhost;
    }
  }

  &--left,
  &--center,
  &--right {
    float: left;
    min-height: 1px;
  }

  &--left {
    width: 40px;
    text-align: left;
    p{
      padding: 1em 0;
    }
  }

  &--center {
    width: calc(100% - 80px);
    text-align: center;
  }

  &--right {
    width: 40px;
    text-align: right;
    p{
      padding: 1em 0;
    }
  }
}