button,
.button,
input[type="submit"],
input[type="reset"],
input[type="button"] {
  @extend .text--body--small;
  display: inline-block;
  position: relative;
  padding: $buttonPadding;
  margin-bottom: 0;
  background-color: $cButtonBg;
  border-radius: $borderRadius;
  color: $cButtonTxt;
  border-width: $borderWidth;
  border-style: solid;
  border-color: $cButtonBg;
  border-radius: $borderRadius;;
  text-decoration: none;
  line-height: $inputLineHeight;
  white-space: nowrap;
  &:disabled{
    color: $cInputLabelD;
    background-color: $cInputBgD;
    border-color: $cInputBorderD;
    cursor: not-allowed;
    pointer-events: none;
  }
  &:hover,
  &:focus {
    color: $cButtonTxtHov;
    background-color: $cButtonBgHov;
  }
  &:focus {
    @include focus;
  }
  &.-thin {
    padding: $inputPaddingThin;
  }
  &.-left {
    text-align: left;
  }
  &.-right {
    text-align: right;
  }
  &.-center {
    text-align: center;
  }
  .-icon > & {
    padding-right: calc(1em + #{$inputPaddingLeft + $inputPaddingRight});
  }
  .input--button & {
    > .input--icon {
      bottom: calc(50% - 1.2em);
    }
  }
  .-wide > & {
    display: block;
    width: 100%;
  }
  .input--side_button > & {
    margin-bottom: 0;
    border-width: $borderWidth;
    border-style: solid;
    border-color: $cButtonBg;
    border-left: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  .-axModeOn & {
    background-color: $cButtonBgAx;
    color: $cButtonTxtAx;
    &:hover,
    &:focus {
      background-color: $cButtonBgHovAx;
    }
  }
  &.-secondary {
    background-color: $cButtonBgS;
    color: $cButtonTxtS;
    border-color: $cButtonTxtS;
    &:hover,
    &:focus {
      background-color: $cButtonBgHovS;
    }
  }
  &.-default {
    background-color: $cPrimary;
    color: $cSecondary;
    border-color: $cSecondary;
    &:hover,
    &:focus {
      color: $cButtonTxtHov;
      background-color: $cButtonBgHov;
    }
  }
}

%clearButtonStyle {
  padding: 0;
  background-color: transparent;
  color: $cPrimary;
  border: 0;
  &:hover,
  &:focus {
    background-color: transparent;
    color: $cHilight;
  }
}

.input--side_button {
  float: left;
}

.-inline + .-inline {
  margin-left: 5px;
}


.button--loading_text {
  display: none;
  .-loading & {
    display: inline-block;
    + span {
      display: none;
    }
  }
}

$buttonLoadingSize: 5px;
$buttonLoadingSpace: 3px;
$buttonLoadingOffset: $buttonLoadingSize + $buttonLoadingSpace;

.button--loading_icon {
  display: inline-block;
  position: relative;
  width: $buttonLoadingOffset*3;
  height: $buttonLoadingSize;
  span {
    position: absolute;
    top: 0; left: 0; bottom: auto; right: auto;
    width: $buttonLoadingSize;
    height: $buttonLoadingSize;
    background-color: white;
    border-radius: 100%;
    @include animation(loadBounce 0.6s infinite 0s);
  }
  &:before,
  &:after {
    content: '';
    position: absolute;
    width: $buttonLoadingSize;
    height: $buttonLoadingSize;
    background-color: white;
    border-radius: 100%;
  }
  &:before {
    top: 0; left: $buttonLoadingOffset; bottom: auto; right: auto;
    @include animation(loadBounce 0.6s infinite 0.2s);
  }
  &:after {
    top: 0; left: $buttonLoadingOffset*2; bottom: auto; right: auto;
    @include animation(loadBounce 0.6s infinite 0.4s);
  }
}



