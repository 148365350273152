// shared input styles
%inputStyle {
  @extend .text--body--small;
  width: 100%;
  background-color: $cInputBg;
  border-width: $borderWidth;
  border-style: solid;
  border-color: $cInputBorder;
  border-radius: $borderRadius;
  line-height: $inputLineHeight;
  color: inherit;
  // BROWSER STYLES
  -webkit-appearance: none;
  .module_form & {
    width: 100%;
  }
  &:hover {
    @include hover;
  }
  &:focus {
    @include focus;
  }
  &:disabled,
  &.ui-state-disabled {
    color: $cInputLabelD;
    background-color: $cInputBgD;
    border-color: $cInputBorderD;
    cursor: not-allowed;
  }
  &.ui-state-readonly{
    color: $cInputLabelD;
    background-color: $cInputBgD;
    border-color: $cInputBorderD;
  }
  .-default & {
    border-color: $cSecondary;
  }
  .-error & {
    border-color: $cInputBorderE;
  }
  .-icon & {
    padding-right: 45px;
    padding-right: calc(20px + 1em);
  }
}
%inputStyleAx {
  .-axModeOn & {
    border-width: $borderWidth;
    border-style: solid;
    border-color: $cInputBorderAx;
    border-radius: $borderRadius;
    &:disabled {
      background-color: $cInputBgD;
      border-color: $cInputBorderD;
      cursor: not-allowed;
    }
  }
}

// TEXT BASED INPUTS
input[type="text"],
input[type="tel"],
input[type="search"],
input[type="url"],
input[type="number"],
input[type="email"],
input[type="password"],
input[type="date"],
input[type="time"] {
  @extend %inputStyle;
  @extend %inputStyleAx;
  box-sizing: border-box;
  padding: $inputPadding;
  &.-thin {
    padding: $inputPaddingThin;
  }
}