.ax_menu {
  position: absolute;
  top: 0; right: 0; bottom: auto; left: 0; z-index: 110;
  background-color: c("jet black");
  height: 0px;
  overflow: hidden;
  a {
    color: c("white");
  }
}

.ax_menu--inner {
  padding: $gutter 0;
}
